@import "../global/variables";

.table, .table-wallet {
	border-collapse: separate;
	border-spacing: 0 8px;
	min-width: 800px;
	width: 100%;

	th,
	td {
		font-size: 14px;
		padding: 13px 10px;
	}

	th {
		background: #e9ecef;
		color: $colorText;
		font-size: 13px;
		font-weight: 500;
		text-align: left;
		text-transform: uppercase;
		vertical-align: bottom;
	}

	td {
		background-color: #fff;
		border-bottom: 1px solid #e9ecef;
		color: $colorTextFade;
		vertical-align: top;
	}

	.action-btn {
		padding: 8px;
	}
}

@media (max-width: 768px) {
	.table {
		min-width: 600px;

		td {
			font-size: 13px;
			padding: 10px;
		}
	}
}

@media (max-width: 480px) {
	.table {
		min-width: 400px;
	}
}
