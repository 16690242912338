@import '../../../assets/styles/inc/global/variables';

.block-card {
	// box-shadow: 0px 0px 14px -3px rgba(34, 60, 80, 0.2);
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	overflow: hidden;
	// padding: 30px;

	.img {
		// border-radius: 0 0 10% 10%;
		height: auto;
		margin-top: -15px;
		max-width: 100%;

		transition: all 0.3s;

		iframe {
			width: 100% !important;
		}
	}

	.preview-card {
		.preview {
			background-color: #fff;
			border-radius: 50% !important;
			height: 100px;
			padding: 7px;
			position: relative;
			transition: all 0.3s;
			z-index: 1;
		}
	}

	.title {
		font-size: 20px;
		margin-top: 55px;
		transition: all 0.3s;
	}

	.content {
		@include text-overflow(2);
	}

	&:hover {
		.img {
			transform: scale(1.02);
		}

		.title {
			font-size: 22px;
		}
	}
}
