@import "../../../../assets/styles/inc/global/variables";

.cmp-tree-node {
	background: $colorBgDark !important;
	padding-right: 30px;
	position: relative;
	width: 240px;

	nav {
		background: #fff;
		border-radius: 6px;
		bottom: -6px;
		box-shadow: 0 5px 10px fade-out(#000, 0.94);
		display: none;
		padding: 6px 40px 6px 6px;
		position: absolute;
		right: -6px;
		width: 170px;
		z-index: -1;

		.button {
			background: $colorPurple;
			border-radius: 6px;
			color: #fff;
			font-size: 12px;
			line-height: 1.2;
			padding: 5px 10px;
			text-align: left;
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 6px;
			}
		}
	}

	.name {
		@include text-overflow(2);
	}

	&._bottom-line,
	&._top-line {
		margin-bottom: 12px !important;

		&:after {
			background: #d0d4da;
			bottom: -12px;
			content: "";
			height: 12px;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			width: 4px;
		}
	}

	&._last {
		margin-left: 12px;

		&:after {
			background: #d0d4da;
			content: "";
			height: 4px;
			left: -12px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 12px;
		}
	}

	&._active {
		z-index: 11 !important;

		nav {
			display: flex;
		}
	}
}
