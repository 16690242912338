@import "../../../../assets/styles/inc/global/variables";

.binary-prize {
	.status-name {
		white-space: pre;
	}

	.rotated {
		transform: rotate(180deg);
	}

	.acc-content {
		max-height: 0;
		overflow: hidden;
		transition: all 0.5s;

		&._view {
			max-height: 10000px;
			transition: all 0.5s;
		}
	}

	.chart-block {
		width: 150px;
	}

	.price {
		font-weight: bold;
	}

	.btn-acc {
		animation: bounce 1s infinite;
	}

	.rotated {
		animation: upBounce 1s infinite;
		transform: rotate(180deg);
	}

	.image-status {
		width: 290px;
		// filter: grayscale(100%);
	}

	@media (max-width: 560px) {
		.image-status {
			width: 130px;
		}
		.chart-block {
			width: 100px;
		}
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(15px);
	}
	100% {
		transform: translateY(0px);
	}
}
