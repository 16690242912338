@import "/src/assets/styles/inc/global/variables";

.wrap {
	box-shadow: 0 0 30px #0000001a;
	right: -100%;
	transition: all .3s;
	width: 480px;
	z-index: 10001;

	&Visible {
		right: 0;
	}
}

@media (max-width: 480px) {
	.wrap {
		width: 320px;
	}

}
