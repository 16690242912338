@import './variables';

body {
	background: $colorBg;
	color: $colorText;
	font: 400 14px/1.4 $fontName;

	&.modal-open {
		#root {
			overflow: hidden;
		}
	}
}

.error {
	color: red;
	font-size: 12px;
	margin: -10px 0 10px 0;
}

.custom-scroll-horizont {
	&::-webkit-scrollbar {
		height: 4px;
		width: 4px;
		background: #fff;
	}

	&::-webkit-scrollbar-thumb:horizontal {
		background: #6648ad;
		border-radius: 10px;
	}
}

.custom-scroll {
	::-webkit-scrollbar-button {
		background-repeat: no-repeat;
		height: 0;
		width: 5px;
	}

	::-webkit-scrollbar-track {
		background-color: #ecedee;
	}

	::-webkit-scrollbar-thumb {
		background-color: #6648ad;
		-webkit-border-radius: 0;
		border-radius: 0;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #7257b3d7;
	}

	::-webkit-scrollbar {
		width: 4px;
	}
}

/* Position */
[class*='pos-abs'] {
	position: absolute;
}

.pos {
	position: relative;
	z-index: 1;

	&-abs {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}

	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

/* Image */
[class*='image-'] {
	height: 100%;
	width: 100%;
}

/* Scroll */
[class*='scroll-'] {
	-webkit-overflow-scrolling: touch;
}

.scroll {
	&-y {
		overflow: hidden auto;
	}

	&-x {
		overflow: auto hidden;
	}

	&-disabled {
		overflow: hidden;
	}
}

/* Text */
b,
strong {
	font-weight: 500;
}

.link {
	color: $colorPurple;
	text-decoration: underline;
}

.tooltip {
	border-bottom: 1px dashed $colorPurple;
	color: $colorPurple;
	cursor: pointer;

	&-modal {
		max-width: 300px;
		opacity: 1 !important;
	}
}

.modal-video {
	border-radius: 6px;
	height: 100%;
	width: 100%;
}
