@import "../../../assets/styles/inc/global/variables";

#main-menu {
	background: #fff;
	bottom: 0;
	box-shadow: 0 0 21px 0 #59667a1a;
	left: 0;
	position: fixed;
	top: 0;
	width: 260px;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background: #7c61bd;
	}

	.logo {
		max-height: 100px;

		img {
			margin-left: 15px;
			height: 75px;
		}
	}

	ul {
		margin-bottom: 30px;

		li {
			.link {
				border-radius: 10px;
				color: $colorText;
				font-size: 14px;
				font-weight: 500;
				line-height: 1;
				margin-bottom: 10px;
				padding: 12px 15px;
				position: relative;
				text-decoration: none;
				transition: all 0.15s;

				.beta {
					border-radius: 5px;
					font-size: 10px;
					padding: 4px 8px;
					line-height: 1;
					background: $colorPurple;
					color: #fff;
				}

				path, circle {
					transition: all .15s;

					&:not([fill="none"]) {
						fill: $colorText;
					}

					&[opacity="0.2"] {
						fill: transparent;
					}
				}

				&._active,
				&:hover {
					background: $menuBackground;
					color: $colorPurple;

					path, circle {
						&:not([fill="none"]) {
							fill: $colorPurple;
						}
					}
				}
			}

			ul {
				display: none;

				.link {
					background: transparent !important;
					color: rgba(43, 43, 43, .6);
					padding: 6px 15px 6px 51px;
					position: relative;

					&:before {
						background: $colorText;
						content: "";
						height: 2px;
						left: 19.5px;
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						width: 8px;
					}
				}
			}

			&._active {
				.arrow {
					transform: rotate(180deg);
				}

				ul {
					display: block;
				}
			}
		}
	}

	.curator {
		background: #f4f4f4;
		padding: 15px;

		.social-links {
			.icon {
				border-radius: 3px;
				height: 28px;
				width: 28px;
			}

			.phone {
				background: #0d4dc1;
			}

			.vk {
				background: #45668e;
			}

			.fb {
				background: #1877f2;
			}

			.sk {
				background: #00aff0;
			}

			.tg {
				background: #08c;
			}
		}

		.line {
			background: #e4e4e4;
			height: 1px;
		}
	}
}

.back-drop-bg {
	display: none;
}

@media #{$mediaXl} {
	.card-body,
	.card-header,
	.card-footer {
		padding: 15px !important;
	}

	#main-menu {
		left: -280px;
		transition-duration: 0.3s;
		z-index: 30 !important;
	}

	.back-drop-bg {
		background: #000007;
		height: 100%;
		opacity: 0.6;
		position: fixed;
		width: 100%;
		z-index: 21;
	}

	.visible {
		transform: translate3d(280px, 0, 0);
		transition-duration: 0.3s;
	}

	.back-active {
		display: block;
	}
}
.beta_bg_grey{
	background-color: gray !important;
}