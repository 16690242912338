.content {
	ul {
		list-style: disc;
		padding-left: 17px;
	}

	.des-file {
		color: #a2a2a2;
		font-size: 12px;
	}
}
