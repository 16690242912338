.cmp-modal {
	bottom: 0;
	left: 0;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	transition: all 0.15s;
	visibility: hidden;
	z-index: 1000;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	.line {
		background-color: #dee2e6;
		height: 1px;
		margin: auto;
		width: 85%;
	}

	.backdrop {
		background: rgba(0, 0, 0, 0.9);
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		transition: all 0.3s ease;
		z-index: -1;
	}

	.modal {
		background: #fff;
		border-radius: 10px;
		max-width: 600px;
		transition: all 0.3s ease;
		width: 96%;

		&-header {
			border-bottom: 1px solid #dee2e6;
			padding: 20px;
		}

		&-body {
			padding: 20px;
		}

		&-footer {
			border-top: 1px solid #dee2e6;
			padding: 20px;
		}
	}

	&._visible {
		opacity: 1;
		visibility: visible;
	}
}
