@import "../global/variables";

.card {
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 0 20px #0815420d;
	margin-bottom: 30px;
	transition: all .3s;

	&-header {
		border-bottom: 1px solid #ecf3fa;
		padding: 30px;
	}

	&-body {
		padding: 30px;
	}

	&-footer {
		border-top: 1px solid #ecf3fa;
		padding: 30px;
	}

	&._sm {
		.card {
			&-header,
			&-body,
			&-footer {
				padding: 20px;
			}
		}
	}

	&._hover {
		&:hover {
			box-shadow: 0 10px 20px rgba(8, 21, 66, 0.13);
		}
	}
}

@media (max-width: 560px) {
	.card {
		margin-bottom: 15px;
	}
}
