@import "../../../assets/styles/inc/global/variables";

.wrap {
	padding-left: 260px;
}

@media #{$mediaXl} {
	.wrap {
		padding-left: 0;
	}
}
