@import '../global/variables';

.cmp-bill-block {
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 0 20px #0815420d;
	cursor: pointer;
	overflow: hidden;
	padding: 15px 20px;
	transition: all 0.3s;
	height: 100px;

	.text-price {
		word-wrap: break-word;
	}

	&._active {
		background: $colorPurple;
		color: #fff;
	}
}

.cmp-bill-block-usdr {
	background: #eaeaea;
	border-radius: 12px;
	box-shadow: 0 0 20px #0815420d;
	cursor: pointer;
	overflow: hidden;
	padding: 15px 20px;
	transition: all 0.3s;

	.text-price {
		word-wrap: break-word;
	}

	&._active {
		background: $colorPurple;
		color: #fff;
	}
}

@media (max-width: 560px) {
	.cmp-bill-block {
		padding: 10px 15px;
	}
	.cmp-bill-block-usdr {
		padding: 10px 15px;
	}
}
