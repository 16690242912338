@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global */
@import "./inc/bootstrap/bootstrap";
@import "./inc/global/fonts";
@import "./inc/global/reset";
@import "./inc/global/custom";

/* Components */
@import "inc/components/card";
@import "inc/components/form";
@import "inc/components/table";
@import "inc/components/wallet-block";
@import "inc/components/bill-block";

/* Media */
@import "./inc/media/1600";
@import "./inc/media/1199";
@import "./inc/media/992";
@import "./inc/media/768";
@import "./inc/media/576";
@import "./inc/media/475";
@import "./inc/media/375";
@import "./inc/media/320";

/* Layouts */
@import "./inc/layouts/footer";

/* Pages */

/* Components */
