@import "../../../assets/styles/inc/global/variables";

.cmp-garant-invest {

	.card {
		&-header,
		&-body,
		&-footer {
			padding: 20px;
		}
	}

	.icon {
		background: $colorPurple;
		border-radius: 6px;
		height: 38px;
		width: 38px;
	}

	&:hover {
		box-shadow: 0 10px 20px rgba(8, 21, 66, 0.13);
	}

}
