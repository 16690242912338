@import "/src/assets/styles/inc/global/variables";

#page-auth {
	.tab-wrap {
		background: #7366ff0f;
		border: 1px solid rgba(115, 102, 255, 0.25);
		color: $colorText;

		.button {
			cursor: pointer;
			display: block;
			padding: 6px 0;

			&._active {
				background: #7366ff1a;
			}
		}
	}
}
