@import "../../../assets/styles/inc/global/variables";

.cmp-notify {
	border-radius: 6px;
	font-size: 14px;
	padding: 10px 20px;

	&._color-primary {
		background: fade-out($colorPurple, .8);
		border: 1px solid $colorPurple;
	}
}
