@import "/src/assets/styles/inc/global/variables";

.date-lesson {
	color: #a2a2a2;
	font-size: 12px;
}

.video-card {
	position: relative;

	iframe {
		border-radius: 10px;
		height: 100%;
		min-height: 300px;
		width: 100%;
	}
}
