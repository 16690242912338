@import '../../../../assets/styles/inc/global/variables';

.btn {
	align-items: center;
	border-radius: 4px;
	cursor: pointer;
	display: inline-flex;
	font-size: 14px;
	justify-content: center;
	line-height: 1.5;
	padding: 7px 28px;
	transition: all 0.3s;

	&:disabled {
		cursor: default !important;
	}

	&-size-xs {
		font-size: 11px;
		padding: 1px 6px;
	}

	&-size-sm {
		font-size: 12px;
	}

	&-size-lg {
		font-size: 18px;
	}

	&-size-xl {
		font-size: 18px;
		padding: 13px 40px;
	}

	&-default {
		background: #7366ff0f !important;
		border: 1px solid #7366ff4f;
		color: $colorText;

		&:hover {
			background: #7366ff1a !important;
			border: 1px solid #7366ff1a;
		}
	}

	&-primary {
		background: $colorPurple !important;
		border: 1px solid $colorPurple;
		color: #fff;

		&:hover {
			background: darken($colorPurple, 8) !important;
			border: 1px solid darken($colorPurple, 8);
		}
	}

	&-blue {
		background: #4f94fa !important;
		border: 1px solid #7eb1fc;
		color: #fff;

		&:hover {
			background: darken(#7eb1fc, 8) !important;
			border: 1px solid darken(#7eb1fc, 8);
		}
	}

	&-bg {
		background: #f8f8f8 !important;
		border: 1px solid #f8f8f8;
		color: #fff;

		&:hover {
			background: darken(#f8f8f8, 8) !important;
			border: 1px solid darken(#f8f8f8, 8);
		}
	}

	&-gray {
		background: #b2b2b296 !important;
		border: 1px solid #b2b2b296;
		color: #fff;

		&:hover {
			background: darken(#b2b2b296, 8) !important;
			border: 1px solid darken(#b2b2b296, 8);
		}
	}

	&-danger {
		background: $colorRed !important;
		border: 1px solid $colorRed;
		color: #fff;

		&:hover {
			background: darken($colorRed, 8) !important;
			border: 1px solid darken($colorRed, 8);
		}
	}
}

@media (max-width: 560px) {
	.btn {
		&-size-xl {
			font-size: 12px;
			margin-bottom: 15px;
			padding: 6px 20px;
		}
	}
}
