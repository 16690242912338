@import "../../../assets/styles/inc/global/variables";

.cmp-progress-table {
	table {
		td {
			&._active {
				color: #007100;
			}

			&._wait {
				color: fade-out($colorText, 0.5);
			}
		}
	}

	@media (max-width: 480px) {
		table {
			thead,
			tbody {
				tr {
					th,
					td {
						padding: 10px;
					}

					.first-table-th,
					.name-table {
						white-space: normal;
						width: 200px;
					}
				}
			}
		}
	}
}
