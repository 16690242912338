@import "../../../assets/styles/inc/global/variables";

#page-header {
	background: #fff;
	box-shadow: 0 0 20px #59667a1a;
	height: 70px;
	z-index: 10;

	.board-show {
		max-height: 150px;
	}

	.dropdown-list {
		background: #fff;
		box-shadow: 0 0 20px #59667a1a;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 100%;
		transform: translate3d(0, 15px, 0);
		transition: all 0.3s;
		visibility: hidden;

		.link-wrap {
			.link {
				border-radius: 5px;
				color: #3c4858;
				font-size: 13px;
				padding: 8px 10px;
				text-decoration: none;

				svg {
					margin-right: 10px;
				}

				&:hover {
					background: #eee;
				}
			}
		}

		&._notification {
			width: 500px;

			table {
				border-spacing: 0;

				td {
					border-bottom: 1px solid rgba(0, 0, 0, 0.05);
					border-radius: 0;
					padding: 13px 0;
				}
			}
		}

		&._profile {
			width: 200px;
		}

		&._visible {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			visibility: visible;
		}
	}

	.num {
		background-color: #ff5858;
		border-radius: 12px;
		color: #fff;
		font-size: 12px;
		max-width: 50px;
		min-width: 16px;
		padding: 0 5px;
		right: -4px;
		text-align: center;
		top: -1px;
	}
}

@media #{$mediaMd} {
	#page-header {
		.name {
			display: none;
		}

		.dropdown-list {
			&._visible {
				max-width: 300px;
				z-index: 10;
			}
		}

		.thumb {
			display: none !important;
		}
	}
}

@media #{$media375} {
	#page-header {
		.dropdown-list {
			&._visible {
				width: 250px;
				z-index: 10;
			}
		}
	}
}
