.static-block {
	color: #fff;
	margin-bottom: 0 !important;
	overflow: hidden;

	.text {
	}

	.value {
	}

	.icon {
	}

	.bgIcon {
		height: 100px;
		opacity: .2;
		right: -14px;
		transition: all .3s;
		width: 100px;
	}

	&:hover {
		.bgIcon {
			opacity: .4;
			transform: translate(0, -50%) rotate(-5deg) scale(1.1);
		}
	}
}
