@import '../../../assets/styles/inc/global/variables';

.cmp-badge {
	border-radius: 4px;
	color: #fff;
	display: inline-block;
	font-size: 11px;
	padding: 2px 12px;
	white-space: nowrap;

	&._primary {
		background: $colorPurple;
	}

	&._gray {
		background-color: gray;
	}

	&._danger {
		background: $colorRed;
	}

	&._green {
		background: $colorGreen;
	}
}
