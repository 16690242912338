@import "../../../../assets/styles/inc/global/variables";

.cmp-linear-generations {
	width: 100%;

	.bg-purple {
		background-color: $colorPurple !important;
	}

	.search-list {
		background-color: #fff;
		box-shadow: 0 18px 25px -8px rgba(0, 0, 0, 0.21);
		left: 15px;
		max-height: 300px;
		overflow: auto;
		position: absolute;
		right: 15px;
		z-index: 3;

		.item {
			cursor: pointer;
			padding: 10px;

			&:hover {
				background-color: #f8f8f8;
			}
		}
	}

	.card-header {
		li {
			&:not(:last-child) {
				&:after {
					content: ">";
					display: inline-block;
					margin: 0 10px;
				}
			}
		}
	}

	.inner-wrap {
		height: 500px;

		.react-transform-wrapper {
			height: 100%;
			width: 100%;
		}
	}

	.child-wrap {
		&:before {
			background: #d0d4da;
			content: "";
			height: 0.75rem;
			left: 15px;
			position: absolute;
			top: 0;
			width: 0.25rem;
		}
	}

	.tree-wrap {
		position: relative;

		& > .flex {
			& > .flex {
				&._last {
					& > .child-inner-wrap {
						flex-direction: row !important;
						padding-left: 0 !important;

						& > .flex {
							&:before {
								left: 15px;
							}

							&:not(:first-child) {
								&:before {
									display: none;
								}

								.node-wrap {
									&:before {
										left: -1000px;
										width: 1000px;
									}
								}
							}
						}
					}
				}

				&:before {
					border-top: 0.25rem solid #d0d4da;
					content: "";
					height: 0.75rem;
					position: absolute;
					top: -0.75rem;
				}

				&:first-child {
					&:before {
						border-left: 0.25rem solid #d0d4da;
						left: 15px;
						right: 0;
					}
				}

				&:nth-child(2) {
					&:before {
						left: 0;
						right: 0;
					}

					.node-wrap {
						&:before {
							background: #d0d4da;
							content: "";
							height: 0.75rem;
							left: 50%;
							position: absolute;
							top: -0.75rem;
							transform: translateX(-50%);
							width: 0.25rem;
						}
					}
				}

				&:last-child {
					&:before {
						border-right: 0.25rem solid #d0d4da;
						left: 4px;
						right: auto;
						width: 15px;
					}
				}
			}
		}
	}

	.child-inner-wrap {
		& > .flex {
			&:before {
				background: #d0d4da;
				content: "";
				height: 1025px;
				left: -0.8rem;
				position: absolute;
				top: -1010px;
				width: 0.25rem;
			}
		}

		.node-wrap {
			&:before {
				background: #d0d4da;
				content: "";
				height: 0.25rem;
				left: -0.8rem;
				position: absolute;
				top: 15px;
				width: 0.8rem;
			}
		}
	}
}

@media (max-width: 1024px) {
	.phone-cmp {
		align-items: center;
		background-color: #000;
		border-radius: 15px;
		display: flex;
		height: 100%;
		justify-content: center;
		opacity: 0.5;
		overflow: hidden;
		position: absolute;
		width: 100%;
		z-index: 1;

		span {
			color: #fff;
		}
	}
	.fullscreen-tree {
		bottom: 0;
		left: 0;
		position: fixed;
		top: 0;
		z-index: 999;
	}
	.close-fullscreen {
		// position: absolute;
		height: 30px;
		left: 30px;
		top: 3px;
		width: 100%;
		z-index: 999;
	}
}
