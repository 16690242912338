.apexcharts-xaxis-label {
  margin-left: 50px !important;
}

.apexcharts-svg {
  min-height: 300px !important;
}

.stretchClassName {
  height: 94.5%;
}

.back-to {
  margin-top: 10px;
}
