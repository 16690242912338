.index-type-element {
  height: 400px;
  margin: auto;
}

.index-type-title {
  position: absolute;
  bottom: 30px;
  font-size: 20px;
}

.index-type-img {
  max-height: 85%;
  width: auto;
  margin: auto;
}