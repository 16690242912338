@import "../../../../assets/styles/inc/global/variables";

._full {
	&.cmp-binary-tree {
		.inner-wrap {
			height: 100% !important;
		}
	}
}

.search-list {
	background-color: #fff;
	box-shadow: 0 18px 25px -8px rgba(0, 0, 0, 0.21);
	left: 15px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	right: 15px;
	z-index: 3;

	.item {
		cursor: pointer;
		padding: 10px;

		&:hover {
			background-color: #f8f8f8;
		}
	}
}

.cmp-binary-tree {
	transition: unset !important;

	.card-header {
		li {
			&:not(:last-child) {
				&:after {
					content: ">";
					display: inline-block;
					margin: 0 10px;
				}
			}
		}
	}

	.inner-wrap {
		height: 500px;

		.react-transform-component {
			height: 100%;
			width: 100%;
		}

		.react-transform-wrapper {
			height: 100%;
			width: 100%;
		}
	}

	.child-main-wrap {
		&:before {
			background: #d0d4da;
			content: "";
			height: 0.75rem;
			left: 50%;
			position: absolute;
			top: -1.5rem;
			transform: translateX(-50%);
			width: 0.25rem;
		}
	}

	.child-wrap {
		&:before {
			border-top: 4px solid #d0d4da;
			content: "";
			height: 0.75rem;
			position: absolute;
			top: -0.75rem;
		}

		&-0 {
			&:before {
				border-left: 4px solid #d0d4da;
				left: 50%;
				right: 0;
			}
		}

		&-1 {
			&:before {
				border-right: 4px solid #d0d4da;
				left: 0;
				right: 50%;
			}
		}
	}

	.open-full {
		background: rgba(0, 0, 0, 0.8);
	}

	.close-full {
		display: none;
	}

	&._full {
		border-radius: 0 !important;
		bottom: 0;
		height: 100% !important;
		left: 0;
		margin-bottom: 0 !important;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1000;

		.open-full {
			display: none !important;
		}

		.close-full {
			background: $colorBg;
			display: flex;
			left: 0;
			right: 0;
			top: 0;
			z-index: 1;
		}
	}

	&._touch {
		height: 300px;
	}

	@media #{$mediaSm} {
		&._touch {
			height: 160px;
		}
	}
}
