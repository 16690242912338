@import "../../../assets/styles/inc/global/variables";

.cmp-pagination {
	background: #fff;
	border-radius: 6px;
	box-shadow: inset 0 0 0 1px #eee;
	overflow: hidden;

	._active {
		background: $colorPurple;
		color: #fff;

		a:hover {
			color: #fff !important;
		}
	}

	a {
		height: 36px;
		width: 36px;

		&:hover {
			color: $colorPurple;
		}

		&:not(:last-child) {
			border-right: 1px solid #eee;
		}
	}
}
