.pop-up-error {
	transition: all 1s;
	top: -300px;
	opacity: 0;
}

.view-pop-up {
	transition: all 1s;
	top: 50px;
	opacity: 1;
}
