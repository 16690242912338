#page-footer {
	background: #fff;
	box-shadow: 0 0 20px #59667a1a;
	font-size: 12px;

	.social-links {
		.icon {
			border-radius: 3px;
			height: 28px;
			width: 28px;
		}

		.vk {
			background: #45668e;
		}

		.tg {
			background: #08c;
		}

		.inst {
			background: #e1306c;
		}
	}
}
