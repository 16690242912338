.wallet-block {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
	color: #fff;
	overflow: hidden;
	padding: 30px;
	position: relative;
	z-index: 1;

	.bg {
		filter: grayscale(100%);
		height: 100%;
		left: 0;
		object-fit: cover;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	.line {
		background: #fff;
		height: 1px;
		margin: 25px 0;
		opacity: .2;
	}

	.icon {
		background: #fff;
		border-radius: 8px;
		height: 42px;
		margin-bottom: 6px;
		width: 42px;
	}

	&._active {
		.bg {
			filter: grayscale(0);
		}
	}
}

@media (max-width: 560px) {
	.wallet-block {
		margin-bottom: 15px;

		.line {
			margin: 15px 0;
		}
	}
}
